// REACT_APP_DEV_URL: https://test.api.navbat.tj/api/v1
// REACT_APP_PROD_URL: https://api.navbat.tj/api/v1
// REACT_APP_CURRENT_URL: https://test.api.navbat.tj/api/v1
// REACT_APP_PROD_URL: https://api.navbat.tj/api/v1
//
export const currentUrl = `https://test.api.navbat.tj/api/v1`
export const hashUrl = `https://test.api.navbat.tj/api/`


// export const currentUrl = `https://api.navbat.tj/api/v1`
// export const hashUrl = `https://api.navbat.tj/api/`